<template>
  <VApp>
    <JwGnbHeader v-if="isPc" />
    <slot />
    <JwFooter v-if="isPc" />
  </VApp>
</template>
<script setup>
import JwGnbHeader from '@/components/common/JwGnbHeader.vue';
import JwFooter from '@/components/common/JwFooter.vue';
import { useDisplay } from 'vuetify';
const { xlAndUp, lgAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
</script>
